import React, { Fragment } from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Img1 from '../images/new/estrutura/1.jpeg'
import Img2 from '../images/new/estrutura/2.jpeg'
import Img3 from '../images/new/estrutura/3.jpeg'
import Img4 from '../images/new/estrutura/4.jpeg'
import Img5 from '../images/new/estrutura/5.jpeg'
import Img6 from '../images/new/estrutura/6.jpeg'
import Img7 from '../images/new/estrutura/7.jpeg'
import Img8 from '../images/new/estrutura/8.jpeg'
import Img9 from '../images/new/estrutura/9.jpeg'
import Img10 from '../images/new/estrutura/10.jpeg'
import Img11 from '../images/new/estrutura/11.jpeg'
import Img12 from '../images/new/estrutura/12.jpeg'
import Img13 from '../images/new/estrutura/13.jpeg'
import Img14 from '../images/new/estrutura/14.jpeg'
import Img15 from '../images/new/estrutura/15.jpeg'
import Img16 from '../images/new/estrutura/16.jpeg'
import Img17 from '../images/new/estrutura/17.jpeg'
import Img18 from '../images/new/estrutura/18.jpeg'
import Img19 from '../images/new/estrutura/19.jpeg'
import Img20 from '../images/new/estrutura/20.jpeg'
import Img21 from '../images/new/estrutura/21.jpeg'
import Img23 from '../images/new/estrutura/23.jpeg'
import Impng2 from '../images/new/estrutura/internas/2.png'
import Impng3 from '../images/new/estrutura/internas/3.png'
import Impng4 from '../images/new/estrutura/internas/4.png'
import Impng5 from '../images/new/estrutura/internas/5.png'
import Impng6 from '../images/new/estrutura/internas/6.png'
import Impng7 from '../images/new/estrutura/internas/7.png'
import Impng8 from '../images/new/estrutura/internas/8.png'
import Impng9 from '../images/new/estrutura/internas/9.png'
import Impng10 from '../images/new/estrutura/internas/10.png'
import Impng11 from '../images/new/estrutura/internas/11.png'
import Impng12 from '../images/new/estrutura/internas/12.png'
import Impng13 from '../images/new/estrutura/internas/13.png'
import Impng14 from '../images/new/estrutura/internas/14.png'
import Impng15 from '../images/new/estrutura/internas/15.png'
import Impng16 from '../images/new/estrutura/internas/16.png'
import Impng17 from '../images/new/estrutura/internas/17.png'
import Impng18 from '../images/new/estrutura/internas/18.png'
import Impng19 from '../images/new/estrutura/internas/19.png'
import Impng20 from '../images/new/estrutura/internas/20.png'
import Impng21 from '../images/new/estrutura/internas/21.png'
import Impng22 from '../images/new/estrutura/internas/22.png'
import Impng23 from '../images/new/estrutura/internas/23.png'
import Impng24 from '../images/new/estrutura/internas/24.png'
import Impng25 from '../images/new/estrutura/internas/25.png'
import Impng26 from '../images/new/estrutura/internas/26.png'
import Impng27 from '../images/new/estrutura/internas/27.png'
import Impng28 from '../images/new/estrutura/internas/28.png'

class VideosCarousel extends React.Component {
  render() {
    var settings = {
      className: "slider variable-width",
      dots: false,
      infinite: true,
      autoplaySpeed: 3500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
            arrows: false
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            arrows: false,
            dots: false
          }
        }
      ]
    }
    
    return (
      <Fragment>
        <Slider {...settings}>
          <div>
            <img src={Img1} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img2} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img3} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img4} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img5} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img6} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img7} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img8} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img9} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img10} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img11} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img12} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img13} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img14} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img15} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img16} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img17} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img18} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img19} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img20} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img21} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Img23} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng2} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng3} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng4} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng5} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng6} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng7} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng8} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng9} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng10} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng11} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng12} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng13} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng14} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng15} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng16} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng17} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng18} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng19} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng20} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng21} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng22} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng23} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng24} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng25} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng26} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng27} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
          <div>
            <img src={Impng28} alt='' style={{ margin: 'auto', width: '100%' }} />
          </div>
        </Slider>
      </Fragment>
    )
  }
}

export default VideosCarousel