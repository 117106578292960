import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Banner from '../images/new/banners/estrutura.png'
import Cta from '../images/new/banners/estrutura-2.png'
import Rocket from '../images/new/banners/estrutura-3.svg'
import Ellipse from '../images/new/icons/ellipse.svg'
import Slider from '../components/SliderEstrutura'

import { 
  section,
  HeaderBanner,
  container,
  content,
  desktop,
  bottomImg
} from '../components/styles/Base.styles'

const Estrutura = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Diferenciais da Estrutura | Um reino de aprendizado e conhecimento" />
    <NavBar />
    <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div>
    <div css={[section, content]} style={{ paddingBottom: 0 }}>
      <div css={container}>
        <h1>Diferenciais da Estrutura</h1>
        <h2>Salas Ambientes</h2>
        <p>A escola estará organizada em espaços de experiências e de aprendizagem que oferecerá aos alunos maior movimentação e autonomia, o que promoverá a responsabilidade, o convívio e o compromisso ao se deslocarem de um espaço para outro.</p>
        <p>Todos os ambientes foram projetados para que cada espaço possua uma estrutura com materiais diversificados correspondente a cada disciplina ou campo de experiência.</p>
        <p>Os materiais diversos servem como elementos que permitam a criança raciocinar de forma coerente, elaborar esquemas conceituais por meio das ações executadas mentalmente a partir de objetos ou situações passíveis de serem manipuladas ou imaginadas de forma concreta.</p>
        <p>A partir das salas e ambientes que oportunizem essas experiências, o aluno será capaz de refletir e aperfeiçoar seus conhecimentos baseados em situações concretas e lógicas. </p>
        <p>Desenvolverá as habilidades e competências esperadas para cada etapa.</p>
        <p>A autonomia em relação ao adulto aumenta e a criança passa a ter mais seus próprios valores morais.</p>
        <h2>Refeitório Educativo</h2>
        <p>Acreditamos que além de formar hábitos, a escola é uma experiência de vida, e se tratando de infância podemos afirmar que será a primeira e mais marcante delas.</p>
        <p>Saber se alimentar bem, não depende apenas da vontade, é preciso ser exposto a oportunidades que façam querer e garantam que as crianças tenham esse contato com bons alimentos. Por isso, nossas escolas não dispõem de cantinas. Toda a alimentação é produzida e fornecida aos nossos alunos.</p>
        <h2>Atividade Física Diária</h2>
        <p>Além de atividades de recreação livre que proporcionem atividades físicas e integração nos diversos espaços disponíveis, a Kingdom School oferecerá o esporte enquanto atividade física organizada além do desenvolvimento físico e motor, mas também para o desenvolvimento social das crianças.</p>
        <p>Por meio da atividade física o aluno se descobre e se desenvolve em diferentes áreas. As trocas de estímulos estimulam diferentes áreas do cérebro, colaborando que o aluno se desenvolva em plenitude.</p>
        <div style={{ marginTop: 72 }}>
          <Slider />
        </div>
        <h2 style={{ marginTop: 72 }}>Natureza</h2>
        <p>A Kingdom School tem como grande diferencial: o reconhecimento da natureza como parte fundamental da vida humana. Neste momento desafiador com inúmeras agendas ambientais mundiais, a nova unidade oportuniza, também, a chance de incluir a reconciliação com o meio ambiente de que todos somos parte.</p>
        <p>Crianças que se formem com visão de trabalhar com a natureza.</p>
        <p>É por meio do contato e da conscientização de que é possível resolver os problemas com novas soluções e não com o mesmo comportamento que os causaram.</p>
        <h2>Artes</h2>
        <p>A importância da arte como forma de expressão e como linguagem. Oferecer diversas formas de vivenciar a arte, seja por meio das artes plásticas, da dança, do teatro e da música, essencial no desenvolvimento dos alunos.</p>
        <ul style={{ paddingBottom: 95 }}>
          <li>
            <img src={Ellipse} alt='' style={{ width: 'auto' }} />
            <p>Na Kingdom School as atividades oferecidas têm como princípio valorizar as diversas formas de expressão proporcionadas pelas atividades artísticas, considerando seus diferentes aspectos.</p>
          </li>
        </ul>
      </div>
    </div>
    <div css={bottomImg}>
      <img src={Rocket} alt='' style={{ width: '15%' }} />
    </div>
    <Footer />
  </Fragment>
)

export default Estrutura
